import 'react-image-lightbox/style.css';

import './styles.css';

import { createFetch } from '@devmoods/fetch';
import { Link, RouteComponentProps, Router, useParams } from '@reach/router';
import * as React from 'react';
import Lightbox from 'react-image-lightbox';
import { useAbortablePromise } from 'use-abortable-promise';

import Layout from '../../components/Layout';

const fetch = createFetch({
  getRootUrl: () => '/api',
  headers: {
    Accept: 'application/json',
  },
});

interface Product {
  id: string;
  name: string;
  price: number;
  currency: string;
  description: string;
  attachments: { url: string; name: string }[];
}

export type QueryCache = NonNullable<ReturnType<typeof useQuery>>;

export const QueryCacheContext = React.createContext<QueryCache | null>(null);

export function QueryCacheProvider(props: { children: React.ReactNode }) {
  const data = useQuery();

  if (data == null) {
    return null;
  }

  return <QueryCacheContext.Provider value={data} {...props} />;
}

export function useQueryCache() {
  const data = React.useContext(QueryCacheContext);
  if (data == null) {
    throw new Error();
  }
  return data;
}

function useQuery() {
  const [result, abort] = useAbortablePromise(async () => {
    const response = await fetch<{ results: Product[] }>('/products');
    return response.jsonData!;
  }, []);

  if (result.loading || !result.data) {
    return null;
  }

  return result.data.results;
}

function ProductView({ product }: { product: Product }) {
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null);

  return (
    <article key={product.id} className="shop-list-item">
      <div>
        <Link to={`/deler/${product.id}`}>{product.name}</Link>

        <div className="shop-list-item-description">{product.description}</div>

        <div className="shop-attachments">
          {product.attachments.map((attachment, i) => (
            <div
              key={attachment.name}
              className="shop-thumbnail"
              onClick={() => setCurrentIndex(i)}
            >
              <img src={attachment.url} />
            </div>
          ))}
        </div>

        {currentIndex != null && (
          <Lightbox
            discourageDownloads
            enableZoom={false}
            mainSrc={product.attachments[currentIndex].url}
            nextSrc={
              product.attachments[
                (currentIndex + 1) % product.attachments.length
              ].url
            }
            prevSrc={
              product.attachments[
                (currentIndex + product.attachments.length - 1) %
                  product.attachments.length
              ].url
            }
            onMovePrevRequest={() => {
              setCurrentIndex(
                (prevIndex) =>
                  ((prevIndex ?? 0) + product.attachments.length - 1) %
                  product.attachments.length
              );
            }}
            onMoveNextRequest={() => {
              setCurrentIndex(
                (prevIndex) =>
                  ((prevIndex ?? 0) + 1) % product.attachments.length
              );
            }}
            onCloseRequest={() => setCurrentIndex(null)}
          />
        )}
      </div>

      <div className="shop-list-item-price">
        {numberWithSeparator(product.price)} {product.currency}
      </div>
    </article>
  );
}

function ProductDetail({}: RouteComponentProps) {
  const { id } = useParams<{ id: string }>();
  const products = useQueryCache();

  const product = products.find((product) => product.id === id);

  if (!product) {
    return <>Ikke funnet</>;
  }

  return (
    <div>
      <div className="shop-breadcrumbs">
        <Link to="/deler">&larr; Tilbake</Link>
      </div>
      <ProductView product={product} />
    </div>
  );
}

function ProductList({}: RouteComponentProps) {
  const products = useQueryCache();

  return (
    <div style={{ marginTop: 30 }}>
      {products.map((product) => (
        <ProductView key={product.id} product={product} />
      ))}
    </div>
  );
}

export default function PartShop() {
  return (
    <QueryCacheProvider>
      <Layout>
        <h2>Til salgs</h2>

        <Router basepath="/deler">
          <ProductDetail path="/:id" />
          <ProductList path="/" />
        </Router>
      </Layout>
    </QueryCacheProvider>
  );
}

function numberWithSeparator(x: number | null) {
  if (x == null) {
    return '';
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}
